<template>

  <!-- Market -->
  <div id="story-mode-market">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div
        class="content"
        :class="{ 'position-relative': statusOfCompleted }"
      >
        <v-app>
          <!-- Header Page Header -->
          <header class="page-header">
            <dashboard-header
              :class="{ 'stamp': statusOfCompleted }"
              :dashboard-items="dashboardPromotion"
              :title="$t('pages.businessGuide.market.title')"
              :guide-visible="guideVisible"
              :completed-step-name="'marketCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="guideVisible = true"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />

            <!-- Divider -->
            <hr>
            <!-- /Divider -->

          </header>
          <!-- /Header Page Header -->

          <!-- Ai Buddy Drawer -->
          <ai-buddy-drawer
            :visible="aiBuddyDrawerVisible"
            section-name="Market"
            help-me-write
            type="market"
            @close="aiBuddyDrawerVisible = false"
          />
          <!-- /Ai Buddy Drawer -->
        </v-app>

        <!-- Business plan additional info -->
        <business-plan-additional-info
          completed-step-name="marketCompleted"
          section-name="Market"
          show-ai-buddy
          @ai-buddy-open="aiBuddyDrawerVisible = true"
        />
        <!-- /Business plan additional info -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Header Coverage -->
        <header>
          <div>
            <h2>{{ $t('pages.businessGuide.market.marketCoverage') }}</h2>
            <p class="text-lead">
              {{ $t('pages.businessGuide.market.determineSizeOfMarket') }}
            </p>
          </div>
        </header>
        <!-- /Header Coverage -->

        <!-- Coverage -->
        <div class="coverage">

          <!-- Grid Wrapper Empty -->
          <div class="grid-wrapper" :class="{ 'active': gridActive }">

            <!-- Add Coverage -->
            <el-row v-if="$store.state.idea.storyMode.research.market.coverageElementSelected === false" :gutter="20">
              <el-col class="card-item position-relative">

                <!-- Completed Overlay -->
                <div
                  v-if="!canEdit || statusOfCompleted"
                  class="completed-overlay"
                  @click="checkMarket"
                />
                <!-- /Completed Overlay -->

                <!-- Add Coverage Button -->
                <div
                  class="idea-card add-new"
                  :class="{ 'completed-card': statusOfCompleted }"
                  @click="onToggleDialogAddCoverage"
                >
                  <el-button type="plus-round-xs" icon="el-icon-plus" />
                  <p class="title">
                    {{ $t('addCoverage') }}
                  </p>
                </div>
                <!-- /Add Coverage Button -->

                <!-- Dialog Add Coverage -->
                <dialog-add-coverage
                  :dialog-add-coverage-visible="dialogAddCoverageVisible"
                  @close-dialog-add-coverage="onCloseDialogAddCoverage"
                />
                <!-- /Dialog Add Coverage -->

                <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
                <tutorial-tooltip
                  v-if="$store.state.user.tutorial.productCompleted === false && 1 === 2"
                  :title="$t('pages.businessGuide.market.addCoverageElement')"
                  :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
                  :tutorial-name="'productCompleted'"
                />
                <!-- /Tutorial Tooltip -->

              </el-col>
            </el-row>
            <!-- /Add Coverage -->

            <!-- Selected Coverage -->
            <el-row v-else :gutter="20">
              <el-col class="card-item position-relative">

                <!-- Completed Overlay -->
                <div
                  v-if="!canEdit || statusOfCompleted"
                  class="completed-overlay"
                  @click="checkMarket"
                />
                <!-- /Completed Overlay -->

                <!-- Coverage Card -->
                <div class="coverage-card" @click="onToggleDialogAddCoverage">

                  <!-- Pen Icon -->
                  <div v-if="canEdit" class="absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
                  </div>
                  <!-- /Pen Icon -->

                  <!-- Coverage Image -->
                  <img :src="require(`@/assets/img/icons/story-mode/market/${coverageElement.image}`)">
                  <!-- /Image -->

                  <!-- Coverage Title -->
                  <h3> {{ coverageElement.title }} </h3>
                  <!-- /Title -->

                  <!-- Coverage Text Description -->
                  <p v-html="coverageElement.text" />
                  <!-- /Text Description -->

                </div>
                <!-- /Coverage Card -->

                <!-- Dialog Add Coverage -->
                <dialog-add-coverage
                  :dialog-add-coverage-visible="dialogAddCoverageVisible"
                  @close-dialog-add-coverage="onCloseDialogAddCoverage"
                />
                <!-- /Dialog Add Coverage -->

              </el-col>
            </el-row>
            <!-- /Selected Coverage -->

          </div>
          <!--/ Grid Wrapper Empty -->

        </div>
        <!-- /Coverage -->

        <!-- Growth Rate -->
        <div class="growth">
          <div class="grid-wrapper active">
            <el-row>
              <el-col :md="18">

                <!-- Header Growth Rate -->
                <header>
                  <div>
                    <h2>{{ $t('pages.businessGuide.market.growthRate') }}</h2>
                    <p class="text-lead">
                      {{ $t('pages.businessGuide.market.defineMarketGrowthRate') }}
                    </p>
                  </div>
                </header>
                <!-- /Header Growth Rate -->

              </el-col>
            </el-row>

            <!-- Add Growth Rate -->
            <el-row v-if="$store.state.idea.storyMode.research.market.growthRateElementSelected === false" :gutter="20">
              <el-col class="card-item position-relative">

                <!-- Completed Overlay -->
                <div
                  v-if="!canEdit || statusOfCompleted"
                  class="completed-overlay" @click="checkMarket"
                />
                <!-- /Completed Overlay -->

                <!-- Add Growth Button -->
                <div
                  class="idea-card add-new"
                  :class="{ 'completed-card': statusOfCompleted }"
                  @click="onToggleDialogAddGrowth"
                >
                  <el-button type="plus-round-xs" icon="el-icon-plus" />
                  <p class="title">
                    {{ $t('addGrowthRate') }}
                  </p>
                </div>
                <!-- /Add Growth Button -->

                <!-- Dialog Add Growth Rate -->
                <dialog-add-growth
                  :dialog-add-growth-visible="dialogAddGrowthVisible"
                  @close-dialog-add-growth="onCloseDialogAddGrowth"
                />
                <!-- /Dialog Add Growth Rate -->

                <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
                <tutorial-tooltip
                  v-if="$store.state.user.tutorial.productCompleted === false && 1 === 2"
                  :title="$t('pages.businessGuide.market.addNewGrowthRate')"
                  :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
                  :tutorial-name="'productCompleted'"
                />
                <!-- /Tutorial Tooltip -->

              </el-col>
            </el-row>
            <!-- /Add Growth Rate -->

            <!-- Selected Growth Rate -->
            <el-row v-else :gutter="20">
              <el-col class="card-item position-relative">

                <!-- Completed Overlay -->
                <div
                  v-if="!canEdit || statusOfCompleted"
                  class="completed-overlay"
                  @click="checkMarket"
                />
                <!-- /Completed Overlay -->

                <!-- Growth Card -->
                <div class="growth-card" @click="onToggleDialogAddGrowth">

                  <!-- Pen Icon -->
                  <div v-if="canEdit" class="absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
                  </div>
                  <!-- /Pen Icon -->

                  <!-- Growth Rate Image -->
                  <img :src="require(`@/assets/img/icons/story-mode/market/${growthRateElement.image}`)" alt="">
                  <!-- /Growth Rate Image -->

                  <!-- Growth Rate Title -->
                  <h3> {{ growthRateElement.title }} </h3>
                  <!-- /Growth Rate Title -->

                  <!-- Growth Rate Text Description -->
                  <p v-html="growthRateElement.text" />
                  <!-- /Growth Rate Text Description -->

                </div>
                <!-- /Growth Card -->

                <!-- Dialog Add Growth Rate -->
                <dialog-add-growth
                  :dialog-add-growth-visible="dialogAddGrowthVisible"
                  @close-dialog-add-growth="onCloseDialogAddGrowth"
                />
                <!-- /Dialog Add Growth Rate -->

              </el-col>
            </el-row>
            <!-- /Selected Growth Rate -->

          </div>
        </div>
        <!-- /Growth Rate -->

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'research'"
        :step="'market'"
        :concept-name="'marketMoreDetails'"
        :completed-step-name="'marketCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'marketCompleted'"
      :step-filled="!!$store.state.idea.storyMode.research.market.coverageElementSelected && !!$store.state.idea.storyMode.research.market.growthRateElementSelected"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="marketExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

  </div>
  <!-- /Market -->

</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessPlanAdditionalInfo from '@/views/Home/StoryMode/Components/BusinessPlanAdditionalInfo.vue'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddCoverage from './Dialogs/DialogAddCoverage'
import DialogAddGrowth from './Dialogs/DialogAddGrowth'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import { mapActions } from 'vuex'

import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'Market',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessPlanAdditionalInfo,
    AddMoreDetails,
    DashboardHeader,
    DialogAddCoverage,
    DialogAddGrowth,
    DialogProcessCompleted,
    MainBottomBar,
    MainNavigation,
    TutorialTooltip
  },

  mixins: [
    MixinProcessCompleted,
    MixinGuide,
    MixinIdeaRoles
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      dialogAddCoverageVisible: false,
      dialogAddGrowthVisible: false,
      tooltipsVisible: true,
      tooltipsBottomVisible: true,
      dialogProcessCompletedVisible: false,
      dashboardPromotion: {
        title: 'Market'
      },
      gridActive: true,
      listActive: false,
      guideVisible: false
    }
  },

  computed: {
    statusOfCompleted () {
      return this.$store.state.idea.storyMode.common.completedSteps.marketCompleted
    },
    coverageElement () {
      return this.$store.state.idea.storyMode.research.market.coverageElement
    },
    growthRateElement () {
      return this.$store.state.idea.storyMode.research.market.growthRateElement
    },
    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.partners'),
        route: 'business-guide-concept-partners'
      }
    },
    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.customer'),
        route: 'business-guide-research-customers'
      }
    }
  },

  created () {
    this.openGuide('marketGuide')
    this.getMarket()
  },

  methods: {
    ...mapActions('idea', [
      'getMarket'
    ]),

    cardImage (img) {
      if (img) {
        return 'background-image: url(' + img + ')'
      } else {
        return ''
      }
    },

    gridVisible () {
      this.gridActive = !this.gridActive
      this.listActive = !this.listActive
    },

    listVisible () {
      this.listActive = !this.listActive
      this.gridActive = !this.gridActive
    },

    checkMarket () {
      if (!this.canEdit) return
      if (this.statusOfCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    },

    onToggleDialogAddCoverage () {
      if (!this.canEdit) return
      this.dialogAddCoverageVisible = !this.dialogAddCoverageVisible
    },

    onCloseDialogAddCoverage () {
      this.dialogAddCoverageVisible = false
    },

    onToggleDialogAddGrowth () {
      if (!this.canEdit) return
      this.dialogAddGrowthVisible = !this.dialogAddGrowthVisible
    },

    onCloseDialogAddGrowth () {
      this.dialogAddGrowthVisible = false
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    }
  }
}
</script>

<style lang="scss" scoped>
  #story-mode-market {
    margin-left: 0;
  }

  .card-item {
    @include media-breakpoint-up($sm) {
      max-width: 250px;
    }
  }
</style>
