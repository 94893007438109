<template>
  <ib-dialog
    empty-header
    :visible="dialogAddGrowthVisible"
    :fullscreen="true"
    @close="closeDialogAddGrowth"
  >
    <ib-dialog-container class="px-1">

      <!-- Header Growth Rate -->
      <header class="header-flex">
        <div>
          <h2>{{ $t('pages.businessGuide.market.addTypes.growthRate.title') }}</h2>
          <p class="mt-1 o-50 text-regular">
            {{ $t('pages.businessGuide.market.addTypes.growthRate.description') }}
          </p>
        </div>
      </header>
      <!-- /Header Growth Rate -->

      <!-- Growth Rate Types -->
      <el-row type="flex" class="flex-column flex-md-row pb-4" :gutter="20">
        <el-col
          v-for="growthRateType in growthRateTypes"
          :key="growthRateType.growthRateElementId"
          :md="6"
          class="d-md-flex mb-3"
        >
          <div class="growth-card" @click="selectGrowthRate( growthRateType.growthRateElementId )">
            <img :src="require(`@/assets/img/icons/story-mode/market/${growthRateType.image}`)" alt="">
            <h3> {{ growthRateType.title }} </h3>
            <p v-html="growthRateType.text" />
          </div>
        </el-col>
      </el-row>
      <!-- /Growth Rate Types -->

    </ib-dialog-container>
    <!-- /Growth Rate Content -->

  </ib-dialog>
</template>

<script>
export default {
  name: 'DialogAddGrowth',
  props: {
    dialogAddGrowthVisible: Boolean
  },
  data () {
    return {
      dialogVisible: false,
      guideDialogVisible: false,
      expandedDialogGuide: true,
      productName: '',
      productDesc: '',
      growthRateTypes: [
        {
          growthRateElementId: 1,
          title: this.$t('pages.businessGuide.market.addTypes.growthRate.new.title'),
          text: this.$t('pages.businessGuide.market.addTypes.growthRate.new.text'),
          image: 'new-icon.svg'
        },
        {
          growthRateElementId: 2,
          title: this.$t('pages.businessGuide.market.addTypes.growthRate.growing.title'),
          text: this.$t('pages.businessGuide.market.addTypes.growthRate.growing.text'),
          image: 'growing-icon.svg'
        },
        {
          growthRateElementId: 3,
          title: this.$t('pages.businessGuide.market.addTypes.growthRate.stagnating.title'),
          text: this.$t('pages.businessGuide.market.addTypes.growthRate.stagnating.text'),
          image: 'stagnation-icon.svg'
        },
        {
          growthRateElementId: 4,
          title: this.$t('pages.businessGuide.market.addTypes.growthRate.shrinking.title'),
          text: this.$t('pages.businessGuide.market.addTypes.growthRate.shrinking.text'),
          image: 'shrinking-icon.svg'
        }
      ]
    }
  },
  methods: {
    toggleDialogGuide () {
      this.guideDialogVisible = !this.guideDialogVisible
    },

    selectGrowthRate (growthRateTypeIndex) {
      let growthRateTypeSelected = {}
      this.growthRateTypes.forEach((growthRateType) => {
        if (growthRateType.growthRateElementId === growthRateTypeIndex) {
          growthRateType.ideaId = this.$store.state.idea.id
          growthRateTypeSelected = growthRateType
          this.$store.commit('idea/setGrowthRateElement', { growthRateElement: growthRateTypeSelected })
          this.$emit('close-dialog-add-growth')
        }
      })
      this.$http.post('story-mode/research/market/select-growth-rate', growthRateTypeSelected)
    },

    closeDialogAddGrowth () {
      this.$emit('close-dialog-add-growth')
    }
  }
}
</script>
